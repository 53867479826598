<template>
  <div ref="homeFrame" data-scroll-container class="relative w-full h-auto overflow-hidden">
    <!-- loading -->
    <div ref="loading"
      class="absolute top-0 left-0 w-full h-svh flex justify-center items-center bg-white overflow-hidden transition-all duration-[2000ms]">
      <img src="../assets/img/desktop/loading/landing-intro-logo.webp" class="h-4/5 w-auto object-cover">
      <span class="loader absolute bottom-[2%] right-14"></span>
    </div>

    <div v-if="!isMobile" class="w-full h-auto overflow-hidden">
      <!-- banner -->
      <div data-scroll data-scroll-id="banner" ref="banner" class="relative w-full h-screen flex overflow-hidden">
        <div bg-left
          class="h-full w-1/2 bg-[#F27300] rotate-[-18deg] origin-right scale-[5] flex justify-end items-center transition-all duration-[1200ms] -translate-x-[150%] ease-in-out overflow-hidden">
          <img watermark src="../assets/img/desktop/banner/landing-home-text-bg.webp"
            class="h-1/2 w-auto object-cover pr-[3%] opacity-5 transition-all duration-[1200ms] translate-x-[120%]">
        </div>
        <div bg-right
          class="h-full w-1/2 bg-white rotate-[-18deg] origin-left scale-[5] flex justify-start items-center transition-all duration-[1200ms] translate-x-[150%] ease-in-out">
          <img src="../assets/img/desktop/banner/landing-intro-logo.webp" class="h-1/3 w-auto object-cover pl-[3%]">
        </div>
        <div top-intro class="absolute top-0 left-[5%] w-auto h-auto transition-all duration-1000 -translate-y-full">
          <div class="h-32 w-24 bg-[#D9D9D9] flex justify-center items-center">
            <img src="../assets/img/desktop/loading/landing-intro-logo.webp" class="h-3/5 w-auto">
          </div>
        </div>
        <div main-character
          class="absolute top-0 left-0 w-full h-full flex justify-center items-center transition-all ease-in-out duration-[1300ms] -translate-y-[130%] origin-top">
          <img src="../assets/img/desktop/banner/landing-home-character.webp"
            class="h-full w-auto scale-150 object-cover">
        </div>
        <div btn-launchapp
          class="absolute bottom-10 left-[5%] w-auto h-auto cursor-pointer transition-all duration-1000 translate-y-[200%] -translate-x-[200%] z-30">
          <div @click="openNewTab('https://t.me/financewithgokubot')" class="w-[557px] h-24 flex launch-app">
            <div class="w-[calc(100%-96px)] h-full bg-[#343434] flex justify-center items-center bg-text">
              <h1 class="font-avenir font-bold text-[50px] leading-none text-white text-shadow-launchapp">Launch app
              </h1>
            </div>
            <div class="h-full w-24 bg-[#D9D9D9] flex justify-center items-center">
              <img src="../assets/img/desktop/banner/goku-landing-mobile-button-play-game.webp"
                class="h-3/5 w-auto transition-all duration-300 -rotate-45 arrow">
            </div>
          </div>
        </div>
      </div>
      <div data-scroll data-scroll-id="about" ref="about" class="relative w-full h-screen flex bg-[#222]">
        <img bg-about src="../assets/img/desktop/about/landing-about-bg.webp"
          class="w-full h-full transition-all duration-[13ms] z-10">
        <div title-about
          class="absolute top-0 right-0 w-auto h-auto transition-all duration-[13ms] origin-bottom-right z-20">
          <h1 class="font-made-tommy font-extrabold text-[400px] leading-none text-white text-shadow-launchapp">GOKU
          </h1>
        </div>
        <div coin1 class="absolute top-0 left-[20%] w-auto h-[18%] transition-all duration-[13ms] z-20">
          <img src="../assets/img/desktop/about/landing-about-coin1.webp" class="h-full w-auto">
        </div>
        <div coin2 class="absolute top-[30%] left-0 w-auto h-[25%] transition-all duration-[13ms] z-20">
          <img src="../assets/img/desktop/about/landing-about-coin2.webp" class="h-full w-auto">
        </div>
        <div coin3 class="absolute bottom-0 left-0 w-auto h-[35%] transition-all duration-[13ms] z-20">
          <img src="../assets/img/desktop/about/landing-about-coin3.webp" class="h-full w-auto translate-y-1/2">
        </div>
        <div layout
          class="absolute top-0 left-0 w-full h-full flex justify-center items-center transition-all duration-[13ms] z-20">
          <div class="relative h-full w-auto">
            <img src="../assets/img/desktop/about/landing-about-framecontent.webp"
              class="h-full w-auto object-cover backdrop-blur-sm rounded-[40px]">
            <div text-goku class="absolute top-[5%] left-0 w-auto h-auto transition-all duration-[13ms]">
              <h1 class="font-made-tommy font-extrabold text-[355px] leading-none text-[#FF7120]">GOKU
              </h1>
            </div>
            <div text-about class="absolute top-[4%] left-0 w-auto h-auto transition-all duration-[13ms]">
              <h1
                class="font-made-tommy font-bold text-[140px] leading-none text-white tracking-widest text-shadow-launchapp">
                ABOUT
              </h1>
            </div>
            <div class="absolute top-[35%] left-[3%] h-[35%] w-auto">
              <img src="../assets/img/desktop/about/landing-about-detailtext.webp"
                class="h-full w-auto object-cover backdrop-blur-sm rounded-[30px]">
            </div>
            <div class="absolute bottom-0 left-0 h-[35%] w-auto">
              <img src="../assets/img/desktop/about/landing-about-frame-cta.webp" class="h-full w-auto object-cover">
              <div @click="openNewTab('https://t.me/gokufinance_chat')"
                class="absolute bottom-0 right-0 h-[20%] w-auto">
                <img src="../assets/img/desktop/about/landing-about-bt-telegram.webp"
                  class="h-full w-auto transition-all duration-300 cursor-pointer grayscale-[100%] hover:grayscale-0">
              </div>
              <div @click="openNewTab('https://t.me/gokufinance_channel')"
                class="absolute bottom-[7%] right-[20%] h-[13%] w-auto">
                <img src="../assets/img/desktop/about/landing-about-bt-jointelegram.webp"
                  class="h-full w-auto transition-all duration-300 cursor-pointer hover:scale-110">
              </div>
            </div>
            <div class="absolute top-0 right-0 h-full w-auto translate-x-[100%] translate-y-[100%]">
              <img character src="../assets/img/desktop/about/landing-about-character.webp"
                class="h-full w-auto object-cover scale-150 transition-all duration-[13ms]">
            </div>
          </div>
        </div>
      </div>
      <div class="w-full h-20 bg-[#222]"></div>
      <div data-scroll data-scroll-id="roadmap" ref="roadmap" class="relative w-full h-[120vh] flex bg-[#222]">
        <div text-run
          class="absolute top-[-13%] left-0 h-[11%] w-[200%] bg-[#2C2C2C] z-30 transition-all duration-[13ms] scale-150 rotate-[-4.5deg] flex justify-center items-center gap-[1%]">
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-white">
            GOKU
          </h1>
          <h1 class="font-made-tommy font-bold text-[119px] leading-none text-[#FF7120]">
            GOKU
          </h1>
        </div>
        <div class="absolute top-0 left-0 w-full h-full z-20">
          <img src="../assets/img/desktop/roadmap/landing-roadmap-sky-bg.webp" class="w-full h-full">
          <div class="absolute bottom-0 left-0 w-full h-screen">
            <div title-road class="absolute top-0 left-0 w-full h-[15%] transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-title-road.webp"
                class="h-full w-auto object-cover scale-150">
            </div>
            <div cloud-center class="absolute top-0 left-0 w-full h-[60%] flex justify-center">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-cloud3-bg.webp"
                class="h-full w-auto object-cover">
            </div>
            <div title-map
              class="absolute top-[17%] left-0 w-full h-[15%] flex justify-end transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-title-map.webp"
                class="h-full w-auto object-cover scale-150">
            </div>
            <div cloud-left
              class="absolute top-[30%] left-0 w-full h-[30%] flex justify-end transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-cloud4-bg.webp"
                class="h-full w-auto object-cover">
            </div>
            <div mountain
              class="absolute bottom-0 left-0 w-full h-auto flex justify-center transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-mountain-bg.webp"
                class="w-full h-auto scale-[2] translate-y-1/2">
            </div>
            <div cloud-right
              class="absolute top-[25%] left-0 w-full h-[25%] flex justify-start transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-cloud2-bg.webp"
                class="h-full w-auto object-cover -translate-x-1/2">
            </div>
            <div jungle
              class="absolute bottom-0 left-0 w-full h-auto flex justify-center transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-jungel-bg.webp"
                class="w-full h-auto scale-150 translate-y-full">
            </div>
            <div character
              class="absolute bottom-0 left-0 w-full h-full flex justify-center transition-all duration-[13ms]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-character.webp"
                class="h-full w-auto object-cover scale-150">
            </div>
            <div q1-2025
              class="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[276px] h-auto roadmap-center cursor-pointer transition-all duration-1000 translate-y-[100vh]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-detail-q12025.webp"
                class="h-auto w-full transition-all duration-300 detail">
              <div class="absolute bottom-0 left-0 w-full h-auto">
                <img src="../assets/img/desktop/roadmap/landing-roadmap-q12025.webp" class="h-auto w-full">
              </div>
            </div>
            <div q4-2024
              class="absolute bottom-[23%] left-[18%] w-[276px] h-auto roadmap-left cursor-pointer transition-all duration-500 translate-y-[100vh]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-detail-q42024.webp"
                class="h-auto w-full translate-x-[10%] translate-y-[15%] opacity-50 detail transition-all duration-300">
              <div class="absolute bottom-0 left-0 w-full h-auto">
                <img src="../assets/img/desktop/roadmap/landing-roadmap-q42024.webp" class="h-auto w-full">
              </div>
            </div>
            <div q2-2025
              class="absolute bottom-[23%] right-[18%] w-[276px] h-auto roadmap-right cursor-pointer transition-all duration-500 translate-y-[100vh]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-detail-q22025.webp"
                class="h-auto w-full translate-x-[-10%] translate-y-[15%] opacity-50 detail transition-all duration-300">
              <div class="absolute bottom-0 left-0 w-full h-auto">
                <img src="../assets/img/desktop/roadmap/landing-roadmap-q2025.webp" class="h-auto w-full">
              </div>
            </div>
            <div q3-2024
              class="absolute bottom-[56%] left-[3%] w-[276px] h-auto roadmap-left cursor-pointer transition-all duration-300 translate-y-[100vh]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-detail-q32024.webp"
                class="h-auto w-full translate-x-[10%] translate-y-[15%] opacity-50 detail transition-all duration-300">
              <div class="absolute bottom-0 left-0 w-full h-auto">
                <img src="../assets/img/desktop/roadmap/landing-roadmap-q32024.webp" class="h-auto w-full">
              </div>
            </div>
            <div q3-2025
              class="absolute bottom-[56%] right-[3%] w-[276px] h-auto roadmap-right cursor-pointer transition-all duration-300 translate-y-[100vh]">
              <img src="../assets/img/desktop/roadmap/landing-roadmap-detail-q32025.webp"
                class="h-auto w-full translate-x-[-10%] translate-y-[15%] opacity-50 detail transition-all duration-300">
              <div class="absolute bottom-0 left-0 w-full h-auto">
                <img src="../assets/img/desktop/roadmap/landing-roadmap-q32025.webp" class="h-auto w-full">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-[4vh] w-full"></div>
      <div data-scroll data-scroll-id="partner" ref="partner" class="relative w-full h-screen flex z-20 bg-[#D9D9D9]">
        <div class="relative w-full h-full bg-[#222] rounded-b-[50px] overflow-hidden">
          <div class="absolute bottom-0 left-0 w-[80%] h-auto overflow-hidden">
            <img src="../assets/img/desktop/partner/landing-partners-gradient-bg.webp"
              class="w-full h-auto translate-x-[-20%] translate-y-[30%] mix-blend-plus-lighter">
          </div>
          <div class="absolute top-0 right-0 w-[80%] h-auto overflow-hidden">
            <img src="../assets/img/desktop/partner/landing-partners-gradient-bg.webp"
              class="w-full h-auto translate-x-[20%] translate-y-[-30%] mix-blend-plus-lighter">
          </div>
          <div text-partner
            class="absolute top-[5%] right-[5%] h-auto w-auto transition-all duration-[2000ms] translate-x-full">
            <h1 class="font-made-tommy font-medium text-[189px] leading-none text-white">
              PARTNERS
            </h1>
          </div>
          <div text-backer
            class="absolute top-[25%] right-[26%] h-auto w-auto transition-all duration-[2000ms] -translate-x-full">
            <h1 class="font-made-tommy font-medium text-[189px] leading-none text-white">
              BACKER
            </h1>
          </div>
          <div class="absolute bottom-0 right-[5%] h-96 w-auto flex gap-10">
            <div class="relative h-full w-56 partner cursor-pointer">
              <div
                class="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-[#222] to-[#383838] rounded-t-3xl transition-all duration-300 partner-bg">
              </div>
              <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-start items-center">
                <img src="../assets/img/desktop/partner/landing-partners-lg-solana.webp"
                  class="w-[80%] h-auto mt-[9%] mb-[8%] transition-all duration-300 partner-logo origin-bottom">
                <h1 class="font-made-tommy font-medium text-[47px] leading-none text-white">
                  SOLANA
                </h1>
              </div>
            </div>
            <div class="relative h-full w-56 partner cursor-pointer">
              <div
                class="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-[#222] to-[#383838] rounded-t-3xl transition-all duration-300 partner-bg">
              </div>
              <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-start items-center">
                <img src="../assets/img/desktop/partner/landing-partners-lg-raydium.webp"
                  class="w-[80%] h-auto mt-[9%] mb-[8%] transition-all duration-300 partner-logo origin-bottom">
                <h1 class="font-made-tommy font-medium text-[47px] leading-none text-white">
                  RAYDIUM
                </h1>
              </div>
            </div>
            <div class="relative h-full w-56 partner cursor-pointer">
              <div
                class="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-[#222] to-[#383838] rounded-t-3xl transition-all duration-300 partner-bg">
              </div>
              <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-start items-center">
                <img src="../assets/img/desktop/partner/landing-partners-lg-solana.webp"
                  class="w-[80%] h-auto mt-[9%] mb-[8%] transition-all duration-300 partner-logo origin-bottom">
                <h1 class="font-made-tommy font-medium text-[47px] leading-none text-white">
                  SOLANA
                </h1>
              </div>
            </div>
            <div class="relative h-full w-56 partner cursor-pointer">
              <div
                class="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-[#222] to-[#383838] rounded-t-3xl transition-all duration-300 partner-bg">
              </div>
              <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-start items-center">
                <img src="../assets/img/desktop/partner/landing-partners-lg-raydium.webp"
                  class="w-[80%] h-auto mt-[9%] mb-[8%] transition-all duration-300 partner-logo origin-bottom">
                <h1 class="font-made-tommy font-medium text-[47px] leading-none text-white">
                  RAYDIUM
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div character-partner class="absolute top-0 left-[3%] h-full w-auto transition-all duration-300">
          <img src="../assets/img/desktop/partner/landing-partners-character.webp"
            class="h-full origin-top-left scale-[1.15] translate-y-[5%]">
        </div>
      </div>
      <div data-scroll data-scroll-id="footer" ref="footer"
        class="relative w-full h-screen flex bg-[#D9D9D9] overflow-hidde">
        <div text-bg class="absolute top-0 left-0 w-full h-auto transition-all duration-[13ms] flex justify-center">
          <img src="../assets/img/desktop/footer/landing-footer-text-bg.webp"
            class="w-full h-auto -translate-y-full max-w-[1433px]">
        </div>
        <div character-footer class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <img src="../assets/img/desktop/footer/landing-footer-character.webp"
            class="h-full w-auto object-cover origin-top scale-125 -translate-y-full">
        </div>
        <div class="absolute bottom-0 left-0 w-full h-2/5 flex justify-center items-center">
          <div
            class="h-full w-full backdrop-blur-lg bg-[#3333332B] rounded-t-3xl flex flex-col justify-center items-center">
            <div class="h-auto w-[60%] max-w-[850px]">
              <img src="../assets/img/desktop/footer/footer-text-join.png" class="h-auto w-full">
            </div>
            <div class="h-auto w-[60%] mt-[1%] max-w-[850px]">
              <img src="../assets/img/desktop/footer/footer-text-community.png" class="h-auto w-full">
            </div>
            <div class="relative h-[15%] w-full flex justify-center items-center mt-[2%] gap-28">
              <img @click="openNewTab('https://t.me/gokufinance_channel')"
                src="../assets/img/desktop/footer/landing-footer-ic-telegram.webp"
                class="h-full w-auto transition-all duration-300 hover:scale-125 cursor-pointer">
              <img @click="openNewTab('https://t.me/chat')"
                src="../assets/img/desktop/footer/landing-footer-ic-telegram.webp"
                class="h-full w-auto transition-all duration-300 hover:scale-125 cursor-pointer">
              <img @click="openNewTab('https://x.com/gokuswap_io')"
                src="../assets/img/desktop/footer/landing-footer-ic-x.webp"
                class="h-full w-auto transition-all duration-300 hover:scale-125 cursor-pointer">
              <img
                @click="openImageFullScreen('https://storage.googleapis.com/gokuminiapp/whitepaper/gokufinance-whitepaper.webp')"
                src="./file-pdf-fill.png"
                class="h-full w-auto transition-all duration-300 hover:scale-125 cursor-pointer">
              <div class="absolute bottom-0 left-10 h-auto w-auto">
                <h1 class="font-made-tommy font-medium text-[#72716dbb] leading-none text-[10px]">
                  © 2024 — GOKU Copyright
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="w-full h-auto overflow-hidden">
      <!-- banner mobile -->
      <div class="relative w-full h-svh flex overflow-hidden">
        <div ref="bannerBgLeft"
          class="h-full w-1/2 bg-[#F27300] rotate-[-18deg] origin-right scale-[5] flex justify-end items-center transition-all duration-700 -translate-x-[200%] ease-in-out overflow-hidden">
          <img src="../assets/img/desktop/banner/landing-home-text-bg.webp"
            class="h-1/2 w-auto object-cover pr-[3%] opacity-5 transition-all duration-[1200ms]">
        </div>
        <div ref="bannerBgRight"
          class="h-full w-1/2 bg-white rotate-[-18deg] origin-left scale-[5] flex justify-start items-center transition-all duration-700 translate-x-[200%] ease-in-out">
          <img src="../assets/img/desktop/banner/landing-intro-logo.webp" class="h-1/3 w-auto object-cover pl-[3%]">
        </div>
        <div ref="bannerMainCharacter"
          class="absolute top-0 left-0 w-full h-full flex justify-center items-center transition-all ease-in-out duration-[1300ms] -translate-y-[100%] origin-top">
          <img src="../assets/img/desktop/banner/landing-home-character.webp" class="h-5/6 w-auto object-cover">
        </div>
      </div>
      <div class="relative w-full h-auto mt-5">
        <img src="../assets/img/mobile/about/mobile-about-bg.webp" class="w-full h-auto">
        <div class="absolute top-[1%] right-0 w-[21%] h-auto">
          <img src="../assets/img/mobile/about/mobile-about-coin1.webp"
            class="h-auto w-full translate-x-[15%] blur-[1px]">
        </div>
        <div class="absolute top-[4%] left-[2%] h-auto w-[35%]">
          <img src="../assets/img/mobile/about/mobile-about-coin2.webp" class="h-auto w-full">
        </div>
        <div class="absolute top-[42%] left-0 h-auto w-[31%]">
          <img src="../assets/img/mobile/about/mobile-about-coin3.webp" class="h-auto w-full -translate-x-[10%]">
        </div>
        <div class="absolute top-[6%] left-0 w-full h-auto flex justify-center">
          <div class="relative w-[95%] h-auto">
            <img src="../assets/img/mobile/about/mobile-about-framecontent.webp" class="w-full h-auto">
            <div class="absolute top-[7%] left-0 w-full h-auto flex justify-end">
              <h1
                class="font-made-tommy font-extrabold text-[28vw] leading-none text-[#FF7120] mr-[2%] text-shadow-launchapp">
                GOKU
              </h1>
            </div>
            <div class="absolute top-[3%] left-0 w-full h-auto flex justify-start">
              <h1 class="font-made-tommy font-bold text-[15vw] leading-none text-white ml-[3%] text-shadow-launchapp">
                ABOUT
              </h1>
            </div>
            <div class="absolute bottom-0 right-0 h-auto w-2/3">
              <img src="../assets/img/mobile/about/mobile-about-character.webp"
                class="w-full h-auto translate-x-[15%] translate-y-[15%]">
            </div>
            <div class="absolute top-[20%] left-[4%] h-auto w-[42%]">
              <img src="../assets/img/mobile/about/mobile-about-frame-cta.webp" class="w-full h-auto">
              <div @click="openNewTab('https://t.me/gokufinance_channel')"
                class="absolute bottom-[3%] left-[8.5%] w-3/5 h-auto">
                <img src="../assets/img/mobile/about/mobile-about-bt-jointelegram.webp" class="w-full h-auto">
              </div>
              <div @click="openNewTab('https://t.me/gokufinance_chat')"
                class="absolute bottom-0 right-0 w-[20%] h-auto">
                <img src="../assets/img/mobile/about/mobile-about-bt-telegram.webp" class="w-full h-auto">
              </div>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 left-0 w-full h-[45%] flex justify-center items-start">
          <div
            class="w-[95%] h-[90%] rounded-3xl border-2 border-[#ffffffb9] backdrop-blur-[20px] flex justify-center items-center">
            <img src="../assets/img/mobile/about/mobile-about-detailtext.webp" class="w-[90%] h-auto">
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto">
        <img src="../assets/img/mobile/roadmap/mobile-roadmap-bg.webp" class="w-full h-auto">
        <div class="absolute top-[5%] left-0 w-1/2 h-auto">
          <img src="../assets/img/mobile/roadmap/mobile-roadmap-cloud4-bg.webp"
            class="w-full h-auto -translate-x-[15%]">
        </div>
        <div class="absolute top-[18%] right-0 w-1/2 h-auto">
          <img src="../assets/img/mobile/roadmap/mobile-roadmap-cloud2-bg.webp"
            class="w-full h-auto translate-x-[25%] rotate-180">
        </div>
        <div class="absolute top-[31.5%] left-0 w-3/4 h-auto">
          <img src="../assets/img/mobile/roadmap/mobile-roadmap-title-road.webp" class="w-full h-auto ml-[2.5%]">
          <div class="absolute top-[80%] left-[31%] w-full h-full flex justify-end">
            <img src="../assets/img/mobile/roadmap/mobile-roadmap-title-map.webp" class="w-auto h-full">
          </div>
        </div>
        <div class="absolute top-0 left-0 w-full h-[40%] flex justify-center items-center">
          <img src="../assets/img/mobile/roadmap/mobile-roadmap-character.webp"
            class="h-3/4 w-auto object-cover mb-[10%]">
        </div>
        <div class="absolute bottom-[7%] left-0 w-full h-[52%]">
          <div class="absolute top-0 left-0 w-full h-1/5 flex justify-start">
            <img src="../assets/img/mobile/roadmap/mobile-roadmap-q32024.webp"
              class="h-full w-auto ml-[2.5%] origin-left scale-125">
          </div>
          <div class="absolute top-[20%] left-0 w-full h-1/5 flex justify-end">
            <img src="../assets/img/mobile/roadmap/mobile-roadmap-q42024.webp"
              class="h-full w-auto mr-[2.5%] origin-right scale-125">
          </div>
          <div class="absolute top-[40%] left-0 w-full h-1/5 flex justify-start">
            <img src="../assets/img/mobile/roadmap/mobile-roadmap-q12025.webp"
              class="h-full w-auto ml-[2.5%] origin-left scale-125">
          </div>
          <div class="absolute top-[60%] left-0 w-full h-1/5 flex justify-end">
            <img src="../assets/img/mobile/roadmap/mobile-roadmap-q22025.webp"
              class="h-full w-auto mr-[2.5%] origin-right scale-125">
          </div>
          <div class="absolute top-[80%] left-0 w-full h-1/5 flex justify-start">
            <img src="../assets/img/mobile/roadmap/mobile-roadmap-q32025.webp"
              class="h-full w-auto ml-[2.5%] origin-left scale-125">
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto bg-[#D9D9D9]">
        <img src="../assets/img/mobile/partner/mobile-partners-bg.webp" class="w-full h-auto">
        <div class="absolute top-0 left-0 w-full h-[34.25%]">
          <img src="../assets/img/mobile/partner/mobile-partners-character.webp"
            class="h-full w-auto origin-bottom-left scale-125">
          <div class="absolute bottom-[10%] right-[2.5%] w-auto h-auto">
            <h1
              class="font-made-tommy font-medium text-[10vw] leading-none text-white text-shadow-launchapp text-right">
              PARTNERS <br> BACKER
            </h1>
          </div>
        </div>
        <div class="absolute bottom-0 left-0 w-full h-[65%] flex justify-center items-center">
          <div class="h-[85%] w-[75%] grid grid-cols-2 gap-10 mb-[10%]">
            <div class="h-full w-full flex items-center justify-center">
              <img src="../assets/img/mobile/partner/mobile-partners-lg-solana.webp" class="h-auto w-full">
            </div>
            <div class="h-full w-full flex items-center justify-center">
              <img src="../assets/img/mobile/partner/mobile-partners-lg-raydium.webp" class="h-auto w-full">
            </div>
            <div class="h-full w-full flex items-center justify-center">
              <img src="../assets/img/mobile/partner/mobile-partners-lg-solana.webp" class="h-auto w-full">
            </div>
            <div class="h-full w-full flex items-center justify-center">
              <img src="../assets/img/mobile/partner/mobile-partners-lg-raydium.webp" class="h-auto w-full">
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-svh bg-[#D9D9D9]">
        <div class="absolute top-[5%] left-0 w-full h-auto">
          <img src="../assets/img/mobile/footer/mobile-footer-text-bg.webp" class="w-full h-auto">
        </div>
        <div class="absolute top-[45%] left-[10%] w-[15%] h-auto">
          <img src="../assets/img/mobile/footer/mobile-footer-ic-goku.webp" class="w-full h-auto">
        </div>
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <img src="../assets/img/mobile/footer/mobile-footer-character.webp" class="h-3/4 w-auto object-cover">
        </div>
        <div
          class="absolute bottom-0 left-0 w-full h-[30%] rounded-t-3xl border border-[#ffffffc2] backdrop-blur-[20px]">
          <div class="absolute bottom-5 left-5 h-auto w-auto">
            <h1 class="font-made-tommy font-medium text-[#72716dbb] leading-none text-[10px]">
              © 2024 — GOKU Copyright
            </h1>
          </div>
          <div class="absolute top-[5%] left-0 w-full h-auto flex justify-center">
            <img src="../assets/img/mobile/footer/mobile-footer-title.webp" class="w-[90%] h-auto">
          </div>
          <div class="absolute bottom-[30%] h-[15%] w-full flex justify-center items-center gap-[10%]">
            <img @click="openNewTab('https://t.me/gokufinance_channel')"
              src="../assets/img/desktop/footer/landing-footer-ic-telegram.webp" class="h-full w-auto">
            <img @click="openNewTab('https://t.me/gokufinance_chat')"
              src="../assets/img/desktop/footer/landing-footer-ic-telegram.webp" class="h-full w-auto">
            <img @click="openNewTab('https://x.com/gokuswap_io')"
              src="../assets/img/desktop/footer/landing-footer-ic-x.webp" class="h-full w-auto">
            <img
              @click="openImageFullScreen('https://storage.googleapis.com/gokuminiapp/whitepaper/gokufinance-whitepaper.webp')"
              src="./file-pdf-fill.png"
              class="h-full w-auto">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import LocomotiveScroll from 'locomotive-scroll';

export default {
  name: 'HomeGoku',
  setup() {
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value <= 768);
    const initialIsMobile = ref(isMobile.value);
    const homeFrame = ref(null);
    const loading = ref(null);
    const banner = ref(null);
    const about = ref(null);
    const roadmap = ref(null);
    const partner = ref(null);
    const footer = ref(null);
    const locoScroll = ref(null);
    let bgLeft;
    let watermark;
    let bgRight;
    let topIntro;
    let mainCharacter;
    let btnLaunchApp;
    let bgAbout;
    let titleAbout;
    let coin1;
    let coin2;
    let coin3
    let layout;
    let textAbout;
    let textGoku;
    let character;
    let textRun;
    let titleRoad;
    let cloudCenter;
    let titleMap;
    let cloudLeft;
    let mountain;
    let cloudRight;
    let jungle;
    let characterRoadmap;
    let q12025;
    let q42024;
    let q22025;
    let q32024;
    let q32025;
    let textPartner;
    let textBacker;
    let characterPartner;
    let textBg;
    let characterFooter;
    const bannerBgLeft = ref(null);
    const bannerBgRight = ref(null);
    const bannerMainCharacter = ref(null);

    function hideLoadingDesktop() {
      loading.value.classList.add('opacity-0');
      bgLeft.classList.remove('-translate-x-[150%]');
      watermark.classList.remove('translate-x-[120%]');
      bgRight.classList.remove('translate-x-[150%]');
      mainCharacter.classList.remove('-translate-y-[130%]');
      topIntro.classList.remove('-translate-y-full');
      btnLaunchApp.classList.remove('translate-y-[200%]');
      btnLaunchApp.classList.remove('-translate-x-[200%]');
      setTimeout(() => {
        mainCharacter.classList.remove('duration-[1300ms]');
        mainCharacter.classList.remove('ease-in-out');
        mainCharacter.classList.add('duration-[13ms]');
        btnLaunchApp.classList.remove('duration-1000');
        btnLaunchApp.classList.add('duration-[13ms]');
      }, 1300);
    }

    function hideLoadingMobile() {
      loading.value.classList.add('opacity-0');
      bannerBgLeft.value.classList.remove('-translate-x-[200%]');
      bannerBgRight.value.classList.remove('translate-x-[200%]');
      bannerMainCharacter.value.classList.remove('-translate-y-[100%]');
    }

    function initBannerElements() {
      bgLeft = banner.value.querySelector('[bg-left]');
      watermark = banner.value.querySelector('[watermark]');
      bgRight = banner.value.querySelector('[bg-right]');
      topIntro = banner.value.querySelector('[top-intro]');
      mainCharacter = banner.value.querySelector('[main-character]');
      btnLaunchApp = banner.value.querySelector('[btn-launchapp]');
    }

    function initAboutElements() {
      bgAbout = about.value.querySelector('[bg-about]');
      titleAbout = about.value.querySelector('[title-about]');
      coin1 = about.value.querySelector('[coin1]');
      coin2 = about.value.querySelector('[coin2]');
      coin3 = about.value.querySelector('[coin3]');
      layout = about.value.querySelector('[layout]');
      textAbout = about.value.querySelector('[text-about]');
      textGoku = about.value.querySelector('[text-goku]');
      character = about.value.querySelector('[character]');
    }

    function initRoadmapElements() {
      textRun = roadmap.value.querySelector('[text-run]');
      titleRoad = roadmap.value.querySelector('[title-road]');
      cloudCenter = roadmap.value.querySelector('[cloud-center]');
      titleMap = roadmap.value.querySelector('[title-map]');
      cloudLeft = roadmap.value.querySelector('[cloud-left]');
      mountain = roadmap.value.querySelector('[mountain]');
      cloudRight = roadmap.value.querySelector('[cloud-right]');
      jungle = roadmap.value.querySelector('[jungle]');
      characterRoadmap = roadmap.value.querySelector('[character]');
      q12025 = roadmap.value.querySelector('[q1-2025]');
      q42024 = roadmap.value.querySelector('[q4-2024]');
      q22025 = roadmap.value.querySelector('[q2-2025]');
      q32024 = roadmap.value.querySelector('[q3-2024]');
      q32025 = roadmap.value.querySelector('[q3-2025]');
    }

    function initPartnerElements() {
      textPartner = partner.value.querySelector('[text-partner]');
      textBacker = partner.value.querySelector('[text-backer]');
      characterPartner = partner.value.querySelector('[character-partner]');
    }

    function initFooterElements() {
      textBg = footer.value.querySelector('[text-bg]');
      characterFooter = footer.value.querySelector('[character-footer]');
    }

    function preventScroll(e) {
      e.preventDefault();
    }

    function preventKeyScroll(e) {
      if (e.code === 'Space' || ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.code)) {
        e.preventDefault();
      }
    }


    function toggleScrollControls(enable) {
      if (!isMobile.value) {
        if (enable) {
          locoScroll.value.start();
        } else {
          locoScroll.value.stop();
        }
      } else {
        if (enable) {
          document.removeEventListener('wheel', preventScroll, { passive: false });
          document.removeEventListener('keydown', preventKeyScroll);
          document.removeEventListener('touchmove', preventScroll, { passive: false });
        } else {
          document.addEventListener('wheel', preventScroll, { passive: false });
          document.addEventListener('keydown', preventKeyScroll);
          document.addEventListener('touchmove', preventScroll, { passive: false });
        }
      }
    }


    function onScroll(event) {
      const scrollY = event.scroll.y;
      const maxScroll = window.innerHeight;

      if (typeof event.currentElements['banner'] === 'object') {
        let progress = event.currentElements['banner'].progress;

        if (progress < 0.587) {
          const scaleValue = 1 + (scrollY / maxScroll * 3);
          const translateYValue = scrollY * 0.5;
          const translateXValue = scrollY * 2;
          const clampedScale = Math.min(scaleValue, 2);

          mainCharacter.style.transform = `scale(${clampedScale}) translateY(${translateYValue}px) translateX(${-translateXValue}px)`;
          btnLaunchApp.style.transform = `translateY(${-translateYValue * 2.5}px)`;

          bgRight.classList.toggle('bg-white', true);
          bgRight.classList.toggle('bg-[#F27300]', false);
          btnLaunchApp.classList.toggle('transition-all', true);
          btnLaunchApp.classList.toggle('duration-[13ms]', true);
          btnLaunchApp.classList.toggle('left-[5%]', true);
          btnLaunchApp.classList.toggle('right-[1%]', false);
          btnLaunchApp.classList.toggle('bottom-60', false);
          btnLaunchApp.classList.toggle('bottom-10', true);
        } else {
          bgRight.classList.toggle('bg-white', false);
          bgRight.classList.toggle('bg-[#F27300]', true);
          btnLaunchApp.classList.toggle('duration-[13ms]', false);
          btnLaunchApp.classList.toggle('transition-all', false);
          btnLaunchApp.classList.toggle('left-[5%]', false);
          btnLaunchApp.classList.toggle('right-[1%]', true);
          btnLaunchApp.classList.toggle('bottom-10', false);
          btnLaunchApp.classList.toggle('bottom-60', true);
        }
      }

      if (typeof event.currentElements['about'] === 'object') {
        let progress = event.currentElements['about'].progress;
        let translateYValue = scrollY * 0.7;
        const scaleValue = 1 + (scrollY / maxScroll);
        const clampedScale = Math.min(scaleValue, 2);
        let rotateValue = (scrollY / maxScroll) * 25;

        if (rotateValue <= 0.21) rotateValue = 0;
        if (translateYValue < 1) translateYValue = 0;

        if (progress < 0.087) {
          bgAbout.style.transform = `scale(${clampedScale}) translateY(${-translateYValue}px) rotate(${-rotateValue}deg)`;
          titleAbout.style.transform = `scale(${clampedScale / 1.5}) translateY(${-translateYValue * 6}px)`;
        }
        coin1.style.transform = `translateY(${-translateYValue / 5}px)`;
        coin2.style.transform = `translateY(${-translateYValue / 4}px)`;
        coin3.style.transform = `translateY(${-translateYValue / 5}px)`;
        layout.style.transform = `translateY(${-translateYValue / 10}px)`;
        textAbout.style.transform = `translateX(${translateYValue / 10}px)`;
        textGoku.style.transform = `translateX(${translateYValue / 4}px)`;
        if (progress < 0.446) {
          character.style.transform = `scale(1.5) translateX(${-translateYValue / 2.5}px) translateY(${-translateYValue}px)`;
        }
      }

      if (typeof event.currentElements['roadmap'] === 'object') {
        let progress = event.currentElements['roadmap'].progress;
        let translateXValue = progress * 1000;
        textRun.style.transform = `scale(1.5) rotate(-4.5deg) translateX(${translateXValue}px)`;
        if (progress > 0.5) {
          q12025.classList.toggle('translate-y-[100vh]', false);
          q22025.classList.toggle('translate-y-[100vh]', false);
          q32024.classList.toggle('translate-y-[100vh]', false);
          q32025.classList.toggle('translate-y-[100vh]', false);
          q42024.classList.toggle('translate-y-[100vh]', false);
          if (progress < 0.7) {
            characterRoadmap.style.transform = `scale(${progress})`;
          }
        } else {
          q12025.classList.toggle('translate-y-[100vh]', true);
          q22025.classList.toggle('translate-y-[100vh]', true);
          q32024.classList.toggle('translate-y-[100vh]', true);
          q32025.classList.toggle('translate-y-[100vh]', true);
          q42024.classList.toggle('translate-y-[100vh]', true);
          let titleMoveValue = progress * 500;
          titleRoad.style.transform = `scale(${1 - (progress / 2)}) translateX(${titleMoveValue}px)`;
          titleMap.style.transform = ` scale(${1 - (progress / 2)}) translateX(${-titleMoveValue}px)`;
          characterRoadmap.style.transform = `scale(${1 - progress})`;
          let jungleMoveValue = progress * 200;
          jungle.style.transform = `scale(${1 - (progress / 2)}) translateY(${-jungleMoveValue}%)`;
          let mountainMoveValue = progress * 100;
          mountain.style.transform = `scale(${1 - (progress / 2)}) scale(${1 - (progress / 2)}) translateY(${-mountainMoveValue}%)`;
          mountain.style.filter = `blur(${progress * 10}px)`;
          cloudCenter.style.filter = `blur(${progress * 5}px)`;
          let cloudMoveValue = progress * 200;
          cloudLeft.style.transform = `translateY(${cloudMoveValue}%)`;
          cloudRight.style.transform = `translateY(${cloudMoveValue}%)`;
        }
      }

      if (typeof event.currentElements['partner'] === 'object') {
        let progress = event.currentElements['partner'].progress;
        if (progress < 0.025) {
          textPartner.classList.toggle('translate-x-full', true);
          textBacker.classList.toggle('-translate-x-full', true);
        } else {
          textPartner.classList.toggle('translate-x-full', false);
          textBacker.classList.toggle('-translate-x-full', false);
        }
        if (progress > 0.9) {
          characterPartner.classList.toggle('opacity-0', true);
        } else {
          characterPartner.classList.toggle('opacity-0', false);
        }
      }

      if (typeof event.currentElements['footer'] === 'object') {
        let progress = event.currentElements['footer'].progress;
        let translateYValue = progress * 200;
        textBg.style.transform = `translateY(${translateYValue * 1.3}%)`;
        characterFooter.style.transform = `translateY(${translateYValue}%)`;
      }
    }

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      if (isMobile.value !== initialIsMobile.value) {
        window.location.reload(true);
      }
    };

    function init() {
      window.addEventListener('resize', handleResize);
      if (!isMobile.value) {
        locoScroll.value = new LocomotiveScroll({
          el: document.querySelector('[data-scroll-container]'),
          smooth: true,
          multiplier: 0.3,
        });
        locoScroll.value.on('scroll', onScroll);
        initBannerElements();
        initAboutElements();
        initRoadmapElements();
        initPartnerElements();
        initFooterElements();
      }
    }

    function openNewTab(url) {
      window.open(url, '_blank');
    }

    function openImageFullScreen(imageUrl) {

      const container = document.createElement('div');
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.width = '100vw';
      container.style.height = '100vh';
      container.style.overflow = 'auto';
      container.style.zIndex = '9999';

      const img = document.createElement('img');
      img.src = imageUrl;
      img.style.width = '100%';
      img.style.height = 'auto';
      img.style.cursor = 'zoom-out';


      container.addEventListener('click', () => {
        document.body.removeChild(container);
      });

      container.appendChild(img);
      document.body.appendChild(container);
    }

    onMounted(() => {
      init();
      toggleScrollControls(false);
      window.addEventListener('load', function () {
        setTimeout(() => {
          if (!isMobile.value) {
            hideLoadingDesktop();
          } else {
            hideLoadingMobile();
          }
          setTimeout(() => {
            toggleScrollControls(true);
          }, 1300);
        }, 1000);
      }, { once: true });
    });

    return {
      homeFrame, isMobile, loading, banner, locoScroll, about, roadmap, partner, footer,
      bannerBgLeft, bannerBgRight, bannerMainCharacter, openNewTab, openImageFullScreen
    }
  }
}
</script>

<style>
.loader {
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 50%;
  color: #FF3D00;
  animation: fill 1s ease-in infinite alternate;
}

.loader::before,
.loader::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  left: 24px;
  top: 0;
  animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
  left: auto;
  right: 24px;
  animation-duration: 1.1s;
}

@keyframes fill {
  0% {
    box-shadow: 0 0 0 2px inset
  }

  100% {
    box-shadow: 0 0 0 10px inset
  }
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('../assets/font/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MADE TOMMY';
  src: url('../assets/font/MADE TOMMY ExtraBold_PERSONAL USE.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'MADE TOMMY';
  src: url('../assets/font/MADE TOMMY Bold_PERSONAL USE.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MADE TOMMY';
  src: url('../assets/font/MADE TOMMY Medium_PERSONAL USE.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

.font-avenir {
  font-family: 'Avenir Next LT Pro', sans-serif;
}

.font-made-tommy {
  font-family: 'MADE TOMMY', sans-serif;
}


.text-shadow-launchapp {
  text-shadow: 3px 4px 3.3px rgba(0, 0, 0, 0.41);
}

.launch-app:hover .bg-text {
  background: linear-gradient(266deg, #FE5E04 0.28%, #CB3A00 98.04%);
}

.launch-app:hover .arrow {
  transform: rotate(0);
}

.roadmap-center:hover .detail {
  transform: translateY(-150%);
}

.roadmap-left:hover .detail {
  transform: translateX(90%) translateY(15%);
  opacity: 1;
}

.roadmap-right:hover .detail {
  transform: translateX(-90%) translateY(15%);
  opacity: 1;
}

.partner:hover .partner-bg {
  height: 240px;
}

.partner:hover .partner-logo {
  transform: scale(1.2);
}
</style>
